import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export function tooltipFormatter(tooltip) {
  /**
   * Generates the content of a datapoint's tooltip when hovered over.
   */

  /* const series = tooltip.chart.userOptions.series[this.series.index];
  const units = series.units;
  const resolution = series.resolution;
  const userTimezone = tooltip.chart.userOptions.timezone || "UTC";
  const timeChart = tooltip.chart.userOptions.timeChart;

  if (series.componentInfo) {
    let componentDt = series.componentInfo.dts[this.point.index];
    componentDt = resolution === "D" ? timeString(
      componentDt[0], componentDt[1], userTimezone
    ) : dayString(componentDt[0], {W: "D", M: "D", Y: "M"}[resolution]);
    const totalDt = dayString(this.point.x, resolution);
    const componentValue = series.seriesType === 3 ? " " : `${roundTo(this.point.y, 2)}${units}`;
    const totalValue = `${roundTo(series.componentInfo.totals[this.point.x], 2)}${units}`;

    return `
      <div class="tooltip-top">${componentDt}</div><br>
      <div class='tooltip-bottom'>${componentValue}</div><br><br>
      <div class="tooltip-top">${totalDt}</div><br>
      <div class='tooltip-bottom'>${totalValue}</div>
    `;
  }
  const timezone = series.timezones ? series.timezones[this.point.index] : "UTC";
  const time = resolution ? dayString(this.point.x, resolution) : timeString(this.point.x, timezone, userTimezone);
  const value = series.seriesType === 3 ? "" : `${timeChart ? durationString(this.point.y) : roundTo(this.point.y, 2)}${units}`; */
  
  const labels = tooltip.chart.userOptions.labels;

  return `
    <div class="tooltip-x">${
      moment(this.point.x).format("DD MMMM YYYY, HH:mm")
    }</div><br>
    <div class='tooltip-y'>${this.point.y} users</div><br>
    <div class='tooltip-label'>${labels[this.point.index] || ""}</div>
  `;
}

const UserGrowthChart = props => {

  const { creations, deletions } = props;

  const creationData = creations.map(creation => [creation.timestamp * 1000, 1, creation.user]);
  const deletionData = deletions.map(deletion => [deletion.timestamp * 1000, -1, deletion.user]);
  const allData = creationData.concat(deletionData).sort((a, b) => a[0] - b[0]);
  const data = allData.reduce((prev, curr) => [...prev, [
    curr[0], prev.length ? prev[prev.length - 1][1] + curr[1] : curr[1], curr[2]
  ]], []);
  const labels = data.map(d => d[2]);
  if (data.length) data.push([new Date().getTime(), data[data.length - 1][1]]);

  const options = {
    chart: {zoomType: "x", padding: 0},
    credits: {enabled: false},
    title: {text: null},
    legend: {enabled: false},
    xAxis: {type: "datetime"},
    yAxis: {title: null},
    series: [{name: "Users", data: data, step: "left", color: "#1c262a"}],
    labels,
    tooltip: {
      formatter: tooltipFormatter,
      style: {lineHeight: "20"},
      borderRadius: 6,
      shadow: false
    },
  }

  return (
    <div className="user-growth-chart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

UserGrowthChart.propTypes = {
  creations: PropTypes.array.isRequired,
  deletions: PropTypes.array.isRequired,
};

export default UserGrowthChart;