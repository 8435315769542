import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const UsersList = props => {

  const { users } = props;

  return (
    <div className="users-list">
      {users.reverse().map(user => (
        <div className="user" key={user.id}>
          <div className="email">{user.email}</div>
          <div className="date">
            {moment(
              user.dateJoinedTimestamp * 1000
            ).format("DD MMMM YYYY, HH:mm")}
          </div>
        </div>
      ))}
    </div>
  );
};

UsersList.propTypes = {
  users: PropTypes.array.isRequired
};

export default UsersList;