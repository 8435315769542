import React, { useContext } from "react";
import PropTypes from "prop-types";
import ClipLoader from "react-spinners/ClipLoader";
import { useQuery } from "@apollo/react-hooks";
import { ACTIVITY } from "../queries";
import Nav from "../components/Nav";
import ActivityMetrics from "../components/ActivityMetrics";
import ActivityChart from "../components/ActivityChart";

const UsersPage = props => {

  

  const {loading, data} = useQuery(ACTIVITY);
  

  if (loading) {
    return <ClipLoader size="100px" css="margin: calc(50vh - 50px) auto; display: block"/>
  }

  const users = data.users.edges.map(edge => edge.node);

  return (
    <div className="activity-page">
      <Nav />
      <ActivityMetrics data={data} />
      <ActivityChart users={users} />
      
    </div>
  );
};

UsersPage.propTypes = {
  
};

export default UsersPage;