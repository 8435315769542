import React from "react";
import LoginForm from "../components/LoginForm";
import Div100vh from "react-div-100vh";

const LoginPage = props => {
  return (
    <Div100vh className="login-page">
      <LoginForm setToken={props.setToken} />
    </Div100vh>
  );
};

LoginPage.propTypes = {
  
};

export default LoginPage;