import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useQuery } from "@apollo/react-hooks";
import { USERS } from "../queries";
import UsersList from "../components/UsersList";
import UserGrowthChart from "../components/UserGrowthChart";
import UserMetrics from "../components/UserMetrics";
import Nav from "../components/Nav";
import Div100vh from "react-div-100vh";

const UsersPage = () => {

  const {loading, data} = useQuery(USERS);

  if (loading) {
    return <ClipLoader size="100px" css="margin: calc(50vh - 50px) auto; display: block"/>
  }

  const users = data.users.edges.map(edge => edge.node);
  const creations = data.userCreations.edges.map(edge => edge.node);
  const deletions = data.userDeletions.edges.map(edge => edge.node);

  return (
    <Div100vh className="users-page">
      <Nav />
      <UserMetrics users={users} />
      <div className="users-panel">
        <div className="left-cell">
          <UsersList users={users} />
        </div>
        <div className="right-cell">
          <UserGrowthChart creations={creations} deletions={deletions} />
        </div>
      </div>
    </Div100vh>
  );
};

UsersPage.propTypes = {
  
};

export default UsersPage;