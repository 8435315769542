import React from "react";
import PropTypes from "prop-types";

const ActivityMetrics = props => {

  const { data } = props;

  const activeUsers = [...new Set(
    data.userRequests.edges.map(edge => edge.node.user.email).filter(Boolean)
  )];
  const creatingUsers = [...new Set(
    data.creations.edges.map(edge => edge.node.user.email).filter(Boolean)
  )];

  return (
    <div className="activity-metrics metrics">
      <div className="metric dau">
        <span className="number">{ activeUsers.length }</span> daily active users
      </div>
      <div className="metric dcu">
        <span className="number">{ creatingUsers.length }</span> daily creating users
      </div>
    </div>
  );
};

ActivityMetrics.propTypes = {
  data: PropTypes.object.isRequired
};

export default ActivityMetrics;