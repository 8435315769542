import gql from "graphql-tag";

export const USERS = gql`{
  users { edges { node { id email dateJoinedTimestamp } } }
  userCreations { edges { node { id timestamp user } } }
  userDeletions { edges { node { id timestamp user } } }
}`;

export const ACTIVITY = gql`{
  userRequests(lastDays: 1) { edges { node { id user { email } } } }
  creations(lastDays: 1) { edges { node { id user { email } } } }
  users { edges { node { id requestDays creationDays } } }
}`;