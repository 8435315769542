import React, { useContext } from "react";
import { useHistory } from "react-router";
import { TokenContext } from "../context";
import { Link } from "react-router-dom";

const Nav = props => {

  const setToken = useContext(TokenContext);

  const history = useHistory();

  const logout = () => {
    setToken(null);
    localStorage.removeItem("token");
    history.push("/");
  }

  return (
    <nav>
      <Link to="/" className={history.location.pathname === "/" ? "selected" : ""}>Users</Link>
      <Link to="/activity/" className={history.location.pathname === "/activity/" ? "selected" : ""}>Activity</Link>
      <div onClick={logout}>Log out</div>
    </nav>
  );
};

Nav.propTypes = {
  
};

export default Nav;