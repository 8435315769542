import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { max } from "moment";

const ActivityGrowthChart = props => {

  const { users } = props;

  const requests = {};
  for (let user of users) {
    for (let day of user.requestDays) requests[day] = (requests[day] || 0) + 1;
  }

  const creations = {};
  for (let user of users) {
    for (let day of user.creationDays) creations[day] = (creations[day] || 0) + 1;
  }

  const up = n => {
    //return n
    const log = 10 ** parseInt(Math.log10(n));
    const nextLog = log * 10;
    if (n > 0.75 * nextLog) {
      return nextLog
    } else {
      return n + log;
    }
  }

  
  
  const requestData = Object.entries(requests).map(d => [parseInt(d[0]) * 1000, d[1]]);
  const creationData = Object.entries(creations).map(d => [parseInt(d[0]) * 1000, d[1]]);
  const maximum = up(Math.max(...requestData.map(d => d[1]), ...creationData.map(d => d[1])));
  
  const tickInterval = n => {
    let interval = 0.01;
    while (n / interval > 10) {
      interval *= 10;
    }
    return interval;
  }

  console.log(tickInterval(maximum), maximum)

  const options = {
    chart: {zoomType: "x", padding: 0},
    credits: {enabled: false},
    title: {text: null},
    legend: {enabled: true},
    xAxis: {type: "datetime"},
    plotOptions: {column: {grouping: false}},
    yAxis: [
      {title: {text: null}, max: maximum, tickInterval: tickInterval(maximum)},
    ],
    tooltip: {shared: true},
    series: [
      {
        name: "Daily Active Users",
        data: requestData,
        color: "#e74c3cB0",
        type: "column",
        yAxis: 0,
        groupPadding: 0,
        pointPadding: requestData.length < 10 ? 0.2 : 0,
        pointWidth: null
      }, {
        name: "Daily Creating Users",
        data: creationData,
        color: "#2980b9B0",
        type: "column",
        yAxis: 0,
        groupPadding: 0,
        pointPadding: creationData.length < 10 ? 0.2 : 0,
        pointWidth: null
      },
    ]
  }

  return (
    <div className="activity-chart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

ActivityGrowthChart.propTypes = {
  users: PropTypes.array.isRequired,
};

export default ActivityGrowthChart;