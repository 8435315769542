import React from "react";
import PropTypes from "prop-types";

const UserMetrics = props => {

  const { users } = props;

  const thirtyDaysAgo = ((new Date()).getTime() / 1000) - (86400 * 30);
  const usersLastThirty = users.filter(
    user => user.dateJoinedTimestamp > thirtyDaysAgo
  );

  return (
    <div className="user-metrics metrics">
      <div className="metric total">
        <span className="number">{ users.length }</span> users
      </div>
      <div className="metric last-30">
        <span className="number">{ usersLastThirty.length }</span> past 30 days
      </div>
    </div>
  );
};

UserMetrics.propTypes = {
  users: PropTypes.array.isRequired
};

export default UserMetrics;