import React, { useState } from "react";
import { Switch, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { makeClient } from "../api";
import { TokenContext } from "../context";
import LoginPage from "../pages/LoginPage";
import UsersPage from "../pages/UsersPage";
import ActivityPage from "../pages/ActivityPage";

export default () => {

  const [token, setToken] = useState(localStorage.getItem("token"));
  const client = makeClient();

  if (token) {
    return (
      <TokenContext.Provider value={setToken}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Switch>
              <Route path="/" exact>
                <UsersPage />
              </Route>
              <Route path="/activity/" exact>
                <ActivityPage />
              </Route>
            </Switch>
          </BrowserRouter>
        </ApolloProvider>
      </TokenContext.Provider>
    )
  }

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <LoginPage setToken={setToken} />
          </Route>
        </Switch>
      </BrowserRouter>
    </ApolloProvider> 
  )
}